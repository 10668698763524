import React, { useState, useEffect, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom';
import Header from '../components/Header'
import Footer from '../components/Footer'
import SideMenu from '../dashboard_components/SideMenu'
import FilterForm from '../dashboard_components/FilterForm'
import BarChartHolder from '../dashboard_components/BarChartHolder'
import MapHolder from '../dashboard_components/MapHolder'
import LineChartHolder from '../dashboard_components/LineChartHolder'
import PieChartHolder from '../dashboard_components/PieChartHolder'
import TableHolder from '../dashboard_components/TableHolder'
import DataQualityHolder from '../dashboard_components/DataQualityHolder'
import { SkeletonQuadrant } from "./SkeletonCard";
import { fetchData } from '../util.js'
import useAxios from './useAxios';
import $ from 'jquery';
import ReactSlider from 'react-slider';
import '../cssMinifiedFiles/tailwind.min.css';
import Popup from "../dashboard_components/Popup/Popup";
import TableChartIcon from '@material-ui/icons/TableChart';
import '../dashboard_components/chart.css'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthContext from '../context/AuthContext';

export default function Dashboard() {

    const [barChartData, setbarChartData] = useState([]);
    const [indicator, setIndicator] = useState(5);
    const [fyear, setFyear] = useState(3);
    const [month, setMonth] = useState(13);
    const [state, setState] = useState(1);
    const [district, setDistrict] = useState(774);
    const [area, setArea] = useState(1);
    const [areaType, setAreaType] = useState('State');
    const [indicatorType, setIndicatorType] = useState('positive');
    const [isSelected, setIsSelected] = useState(false);
    const [indicatorCategory, setIndicatorCategory] = useState(1);
    const [areaDetails, setAreaDetails] = useState([]);
    const [fyDropdown, setFYDropdown] = useState([]);
    const [monthDropdown, setMonthDropdown] = useState([]);
    const [indicatorDropdown, setIndicatorDropdown] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);
    const [districtDropdown, setDistrictDropdown] = useState([]);
    const location = useLocation();
    const [lineChartData, setLineChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [qualityData, setQualityData] = useState([]);
    const [indicatorVariant, setIndicatorVariant] = useState('Both');
    const [min, setMin] = useState(25);
    const [max, setMax] = useState(50);
    const [isOpenTable, setIsOpenTable] = useState(false);
    const [isColorCodedTable, setIsColorCodedTable] = useState(false);
    const [toggleValue, setToggleValue] = useState('percent');
    let { user, logoutUser } = useContext(AuthContext);
    let areaStateId = user.state;
    const api = useAxios();


    useEffect(() => {

        async function getInitialData() {

            const [response1, response2, response3, response4] = await Promise.all([
                api.get(`/ina_area/${areaStateId}`),
                api.get('/fy'),
                api.get('/month'),
                api.get('/indicators')
            ]);

            if (response1.status === 200) {

                let areaData = response1.data
                setAreaDetails(areaData);
                let stateData = areaData.filter(f => (f.area_type === 'State'));
                let districtData = areaData.filter(f => (f.area_type === 'District'));
                setStateDropdown(stateData);
                setDistrictDropdown(districtData);

                let fyData = response2.data.filter(m => (m.fy_id < 5));
                setFYDropdown(fyData);

                let monthData = response3.data.filter(m => (m.month_id < 14));
                setMonthDropdown(monthData);

                let indicatorData = response4.data.filter(i => (i.indicator_id < 37 && i.indicator_id != 29));
                setIndicatorDropdown(indicatorData);
            } else if (response1.statusText === 'Unauthorized') {
                logoutUser()
            }
        }
        getInitialData();

    }, []);

    useEffect(() => {
        setIsSelected(false);

        async function getNewIndicatorData() {

            const indiData = await api.get('/indicators');
            let indicatorList = indiData.data.filter(i => (i.indicator_id < 37 && i.indicator_id != 29));
            let url = location.pathname;
            let newUrl = url.split('/');
            let indicator_label = newUrl[newUrl.length - 1];
            let indicatorId = 5, indicator_cat = 1;
            if (indicator_label != 'dashboard' && indicator_label != undefined && indicator_label != "") {
                let indicatorObject = indicatorList.filter(f => f.name === indicator_label)[0]
                indicatorId = indicatorObject.indicator_id;
                let indicator_type = indicatorObject.type;
                if (indicator_type === "negative") {
                    $('.example-track-0').css("background", "#30A054");
                    $('.example-track-2').css("background", "#ff0000");
                }
                else {
                    $('.example-track-0').css("background", "#ff0000");
                    $('.example-track-2').css("background", "#30A054");
                }
                indicator_cat = indicatorObject.category;
                let indicator_variant = indicatorObject.variant;

                if (indicator_variant === 'Numeric')
                    setToggleValue('count')
                else
                    setToggleValue('percent');

                setIndicator(indicatorId);
                setIndicatorType(indicator_type);
                setIndicatorCategory(indicator_cat);
                setIndicatorVariant(indicator_variant);
            } else {
                indicatorId = 5;
                let indicator_type = 'positive';
                let indicator_cat = 1;
                let indicator_variant = 'Both';
                setIndicator(indicatorId);
                setIndicatorType(indicator_type);
                setIndicatorCategory(indicator_cat);
                setIndicatorVariant(indicator_variant);
            }
            await fetchData(fyear, month, area, indicatorId, areaType, indicator_cat, setbarChartData, setLineChartData, setTableData, setQualityData, api, logoutUser);
            setIsSelected(true);
        }
        getNewIndicatorData();

    }, [location]);

    const displayValues = (e) => {
        setMin(e[0]);
        setMax(e[1]);
    };

    const toggleTablePopup = () => {
        setIsOpenTable(!isOpenTable);
    };

    const toggleColorTablePopup = () => {
        setIsColorCodedTable(!isColorCodedTable);
    }

    const ToggleButtonChange = (event, newAlignment) => {
        setToggleValue(newAlignment);
    };


    const theme = createTheme({
        components: {
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        border: `1px solid #000`,
                        color: '#000',
                        fontWeight: 'bold'
                    },
                },
            },
        },
    });


    if (indicator === 9 || indicator === 10) {
        $("#toggleButtonLabel").text('Rate');
    } else
        $("#toggleButtonLabel").text('Percent');

    //Disabling Toggle Button for only Percent and only Numeric parameters
    let disablingToggleButton = false;
    if (indicatorVariant === 'Percent' || indicatorVariant === 'Numeric')
        disablingToggleButton = true;

    let ToggleButtonSwitch = <ThemeProvider theme={theme}>
        <ToggleButtonGroup
            color="info"
            value={toggleValue}
            fullWidth={true}
            exclusive
            onChange={ToggleButtonChange}
            aria-label="Platform"
            variant="contained"
            disabled={disablingToggleButton}
        >
            <ToggleButton value="percent"><span id="toggleButtonLabel"></span></ToggleButton>
            <ToggleButton value="count">Count</ToggleButton>
        </ToggleButtonGroup>
    </ThemeProvider>

    let isDisabled = false;
    if (toggleValue === 'count')
        isDisabled = true;

    return (
        <React.Fragment>
            <Header />

            <div class="container-fluid">
                <div class="row">
                    <SideMenu />
                    <main class="col hmis-dashboard-main md:mx-2.5">
                        <div class="container-2xl m-0">
                            <div class="flex md:flex-wrap flex-wrap md:mb-1 md:px-1">
                                <div class="flex w-full md:w-3/4">
                                    <h2 class="text-3xl font-bold text-black md:text-2xl lg:text-3xl">HMIS Dashboard <span class="hmis-beta">Beta</span></h2>
                                    <div class="flex pt-1">
                                        <a href='/assets/document/India_HMIS_analyzer_manual_indicators_details_18Nov24.pdf' target='_blank'>
                                            <img
                                                src='/assets/icons/info.png'
                                                className='info-icon object-cover h-7'
                                                title='Parameter Details'
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div class="flex flex-row w-full md:w-1/4 justify-center">
                                    {/* <Link to="#">
                                        <button className="bg-cyan-900 hover:bg-gray-900 text-white font-bold text-md mb-1 py-2 px-4 mt-1 rounded-full">Cumulative</button>
                                    </Link> */}
                                    <Link to="/compare">
                                        <button className="bg-cyan-900 text-white font-bold text-md mb-1 ml-3 py-2 px-4 mt-1 rounded-full hover:bg-gray-900">Compare</button>
                                    </Link>
                                </div>
                            </div>
                            <FilterForm fyear={fyear}
                                state={state}
                                district={district}
                                month={month}
                                area={area}
                                indicator={indicator}
                                areaType={areaType}
                                indicatorCategory={indicatorCategory}
                                stateDropdown={stateDropdown}
                                districtDropdown={districtDropdown}
                                fyDropdown={fyDropdown}
                                monthDropdown={monthDropdown}
                                setAreaType={setAreaType}
                                setArea={setArea}
                                setMonth={setMonth}
                                setFyear={setFyear}
                                setState={setState}
                                setDistrict={setDistrict}
                                setbarChartData={setbarChartData}
                                setLineChartData={setLineChartData}
                                setTableData={setTableData}
                                setQualityData={setQualityData}
                                isSelected={isSelected}
                                setIsSelected={setIsSelected}
                            />
                            {(area < 39) ? (
                                <div class="flex flex-col-reverse flex-wrap my-2 md:my-0 md:flex-row md:px-1 items-center">
                                    <div className='flex w-full lg:w-1/5 justify-center'>
                                        <div className='w-11/12'>
                                            {ToggleButtonSwitch}
                                        </div>
                                    </div>

                                    <div className='flex w-full lg:w-2/5'>
                                        <ReactSlider
                                            className="horizontal-slider max-w-xs md:max-w-lg flex items-center justify-center"
                                            thumbClassName="example-thumb"
                                            trackClassName="example-track"
                                            defaultValue={[25.0, 50.0]}
                                            ariaLabel={["Leftmost thumb", "Rightmost thumb"]}
                                            min={0}
                                            max={100}
                                            onChange={displayValues}
                                            step={0.1}
                                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                                            pearling
                                            disabled={isDisabled}
                                        />
                                    </div>

                                    <div className='flex w-full lg:w-2/5'>
                                        <div className='flex w-1/2 items-center justify-center'>
                                            {isColorCodedTable && <Popup
                                                content={<>
                                                    <TableHolder
                                                        tableData={tableData}
                                                        indicatorCategory={indicatorCategory}
                                                    />
                                                </>}
                                                handleClose={toggleColorTablePopup}
                                            />}
                                            <a onClick={toggleColorTablePopup}>
                                                <button className="text-black font-bold text-md py-2 md:px-3 border-1 rounded-lg border-black hover:bg-blue-100 hover:underline">
                                                    <TableChartIcon className='pb-1' style={{ fontSize: '1.875rem' }} />
                                                    Color Coded Table
                                                </button>
                                            </a>
                                        </div>

                                        <div className='flex w-1/2 items-center justify-center'>
                                            {isOpenTable && <Popup
                                                content={<>
                                                    <DataQualityHolder
                                                        qualityData={qualityData}
                                                        area={area} />
                                                </>}
                                                handleClose={toggleTablePopup}
                                            />}
                                            <a onClick={toggleTablePopup}>
                                                <button className="text-black font-bold text-md py-2 px-3 border-1 rounded-lg border-black hover:bg-blue-100 hover:underline">
                                                    <TableChartIcon className='pb-1' style={{ fontSize: '1.875rem' }} /> Data Quality
                                                </button>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            ) :
                                (
                                    <div class="flex my-2 md:my-0 md:px-1 items-center">
                                        <div className='flex w-1/4 justify-center'>
                                            {ToggleButtonSwitch}
                                        </div>
                                    </div>
                                )}

                            {
                                isSelected ? (
                                    <section id='main_dashboard_container' className='flex flex-col' >
                                        <section
                                            id='top_dashboard_row'
                                            className='flex md:flex flex-wrap'
                                        >
                                            <div className='flex w-full md:w-1/2'>
                                                {(barChartData != null && barChartData != '') ?
                                                    <MapHolder barChartData={barChartData}
                                                        areaType={areaType}
                                                        area={area}
                                                        state={state}
                                                        district={district}
                                                        indicatorType={indicatorType}
                                                        indicatorVariant={indicatorVariant}
                                                        areaDetails={areaDetails}
                                                        minVal={min}
                                                        maxVal={max}
                                                        toggleValue={toggleValue}
                                                    /> : null}
                                            </div>

                                            <div className='flex w-full md:w-1/2'>
                                                {(barChartData != null && barChartData != '') ?
                                                    <BarChartHolder
                                                        barChartData={barChartData}
                                                        indicatorType={indicatorType}
                                                        toggleValue={toggleValue}
                                                        indicatorCategory={indicatorCategory}
                                                        indicatorVariant={indicatorVariant}
                                                        minVal={min}
                                                        maxVal={max}
                                                    /> : null}
                                            </div>
                                        </section>

                                        <section
                                            id='bottom_dashboard_row'
                                            className='flex flex-wrap pt-3'
                                        >
                                            <div className='flex w-full md:w-1/2'>
                                                {(lineChartData != null && lineChartData != '') ?
                                                    <LineChartHolder
                                                        lineChartData={lineChartData}
                                                        toggleValue={toggleValue}
                                                        indicatorCategory={indicatorCategory}
                                                        indicatorVariant={indicatorVariant}
                                                    /> : null}
                                            </div>
                                            <div className='flex w-full md:w-1/2'>
                                                {(barChartData != null && barChartData != '' && indicatorVariant != 'Percent') ?
                                                    <PieChartHolder
                                                        barChartData={barChartData}
                                                        indicatorType={indicatorType}
                                                    /> : null}
                                            </div>
                                        </section>
                                    </section>
                                ) : (
                                    <SkeletonQuadrant />
                                )}
                        </div>
                        <>
                            <Footer />
                        </>
                    </main>
                </div>
            </div >
        </React.Fragment >
    )

}