import React, { Component, useState, useEffect, useRef } from 'react'
import { fetchDataCompare } from '../util.js'
import useAxios from '../pages/useAxios';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';

const ValueContainer = ({ children, getValue, ...props }) => {
    var values = getValue();
    var valueLabel = "";

    if (values.length > 0)
        valueLabel += props.selectProps.getOptionLabel(values[0]);
    if (values.length > 1) valueLabel += ` & ${values.length - 1} more`;

    // Keep standard placeholder and input from react-select
    var childsToRender = React.Children.toArray(children).filter(
        child =>
            ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
    );

    return (
        <components.ValueContainer {...props}>
            {!props.selectProps.inputValue && valueLabel}
            {childsToRender}
        </components.ValueContainer>
    );
};

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export const AreaCompare = ({ fyear, state, district, month, area, indicator, areaType, indicatorCategory, stateDropdown, districtDropdown, fyDropdown, indicatorDropdown, setAreaType, setIndicator, setArea, setMonth, setFyear, setState, setDistrict, level, setLevel, status, setbarChartData, setLineChartData, setTableData, setQualityData, isSelected, setIsSelected, toggleValue, setFound }) => {
    // console.log(districtDropdown.filter(district => state.includes(district.area_parent_id)))

    const api = useAxios();
    const [selectedAreas, setSelectedAreas] = useState();
    const updatedAreas = async (e) => {
        setSelectedAreas(e);
    };
    const selectInputRef = useRef();

    let found_1 = indicatorDropdown.filter(e => e.indicator_id === indicator[0])[0].variant;

    if (toggleValue === 'count') {
        if (found_1 === 'Numeric' || found_1 === 'Both') {
            setFound(true);
        } else {
            setFound(false);
        }
    } else {
        if (found_1 === 'Percent' || found_1 === 'Both') {
            setFound(true);
        } else {
            setFound(false);
        }
    }

    const handleLevelChange = async (e) => {
        setIsSelected(false);
        const levelSelected = e.target.value;
        setLevel(levelSelected);
        setState([1]);
        setArea([1]);
        setAreaType(['State']);
        let areaReset = [1];
        let indicatorReset = [5];
        let monthReset = 13;
        let areaTypeReset = 'State'
        await fetchDataCompare(fyear, monthReset, areaReset, indicatorReset, areaTypeReset, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
        setIsSelected(true);
    };

    const handleFinancialYear = async (e) => {
        setIsSelected(false);

        const fyearSelected = e.target.value;
        setFyear(fyearSelected);
        await fetchDataCompare(fyearSelected, month, area, indicator, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
        setIsSelected(true);
    };

    const handleMonthChange = async (e) => {
        setIsSelected(false);

        let monthSelected = e.target.value;
        setMonth(monthSelected);
        await fetchDataCompare(fyear, monthSelected, area, indicator, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
        setIsSelected(true);
    };

    const handleIndicatorChange = async (e) => {
        setIsSelected(false);

        let val = e.target.value;
        setIndicator([Number(val)]);

        let indicatorSelected = val;
        await fetchDataCompare(fyear, month, area, indicatorSelected, areaType, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api);
        setIsSelected(true);
    };

    const handleStateChange = async (e) => {
        // setIsSelected(false);
        let val = Array.isArray(selectedAreas) ? selectedAreas.map(x => x.value) : [selectedAreas.value];
        console.log("val", val);
        let areaSelected = val.join(',');
        let areaTypeSelected = 'State';
        if (val.includes(1) && val.length == 1) {
            setAreaType('State');
            setArea([1]);
        }
        else {
            setAreaType('District');
            areaTypeSelected = 'District';
            setArea(val);
        }

        setState(val);
        await fetchDataCompare(fyear, month, areaSelected, indicator, areaTypeSelected, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api)
        // setIsSelected(true);
        if (level === "district")
            setLineChartData(null);
        selectInputRef.current.clearValue();

    };

    const handleSingleStateChange = async (e) => {
        // setIsSelected(false);
        let val = Array.isArray(e) ? e.map(x => x.value) : [e.value];
        let areaSelected = val.join(',');
        let areaTypeSelected = 'State';
        if (val.includes(1) && val.length == 1) {
            setAreaType('State');
            setArea([1]);

        }
        else {
            setAreaType('District');
            areaTypeSelected = 'District';
            setArea(val);
        }

        setState(val);
        await fetchDataCompare(fyear, month, areaSelected, indicator, areaTypeSelected, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api)
        // setIsSelected(true);
        if (level === "district")
            setLineChartData(null);
        selectInputRef.current.clearValue();
    };

    const handleDistrictChange = async (e) => {
        // setIsSelected(false);

        let val = Array.isArray(selectedAreas) ? selectedAreas.map(x => x.value) : [selectedAreas.value];
        let areaSelected = val.join(',');
        let areaTypeSelected = 'District';

        console.log(val)

        if (val.includes(774) && val.length == 1) {
            setAreaType('District');
            setArea(state);
        }
        else {
            setAreaType('District');
            setArea(val);
            areaTypeSelected = 'District';
        }

        // if (val.includes(774)) {
        //     let valIndex = val.indexOf(774);
        //     val[valIndex] = state[0];
        // }

        setDistrict(val);
        await fetchDataCompare(fyear, month, areaSelected, indicator, areaTypeSelected, indicatorCategory, status, setbarChartData, setLineChartData, setTableData, setQualityData, api)
        // setIsSelected(true);
    };


    return (
        <React.Fragment>
            <form action="" class="mb-3">
                <div class="flex flex-wrap md:flex-row">
                    <div className='flex w-full md:w-1/2 lg:w-1/6'>
                        <div class="flex-col w-11/12">
                            <label for="" class="block mb-1 mt-2 md:mt-0 text-base font-bold text-black">Select Level</label>
                            <select name="level" id="" class="form-select bg-gray-50 border border-gray-500 text-black text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                                value={level}
                                onChange={handleLevelChange}
                                disabled={!isSelected}>
                                <option value="state">State</option>
                                <option value="district">District</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex w-full md:w-1/2 lg:w-1/6'>
                        <div class="flex-col w-11/12">
                            <label for="" class="block mb-1 mt-2 md:mt-0 text-base font-bold text-black">Select Financial Year</label>
                            <select name="year" id="" class="form-select bg-gray-50 border border-gray-500 text-black text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                                value={fyear}
                                onChange={handleFinancialYear}
                                disabled={!isSelected} >
                                <option value='All'>All</option>

                                {fyDropdown.map((year) => {
                                    return (
                                        <option value={year.fy_id}>{year.fy_name}</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='flex w-full lg:w-2/6'>
                        <div class="flex-col w-11/12">
                            <label for="" class="block mb-1 mt-2 md:mt-0 text-base font-bold text-black">Select Indicator</label>
                            <select name="indicator" id="" class="form-select bg-gray-50 border border-gray-500 text-black text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                                value={indicator}
                                onChange={handleIndicatorChange}
                                disabled={!isSelected}>
                                {indicatorDropdown.sort((a, b) => a.category - b.category).map((indicator) => {
                                    return (
                                        <option value={indicator.indicator_id}>{indicator.label}</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='flex w-full md:w-1/2 lg:w-1/6'>
                        <div class="flex-col w-11/12">
                            <label for="" class="block mb-1 mt-2 md:mt-0 text-base font-bold text-black">Select State</label>
                            {level === "state" &&
                                <Select
                                    class='bg-gray-50 border border-gray-500 text-black text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full'
                                    options={stateDropdown.map(e => ({ label: e.area_name, value: e.area_id }))}
                                    closeMenuOnSelect={false}// set list of the data
                                    onChange={updatedAreas} // assign onChange function
                                    // isOptionDisabled={() => state.length >= 3}
                                    isMulti
                                    defaultValue={{ label: "All (India)", value: 1 }}
                                    // maximumSelectionLength={3}
                                    maxMenuHeight="50"
                                    isClearable
                                    showCheckbox={true}
                                    hideSelectedOptions={false}
                                    onMenuClose={handleStateChange}
                                    isDisabled={!isSelected}
                                    components={{
                                        ValueContainer,
                                        Option
                                    }}
                                />


                            }
                            {level === "district" &&
                                <Select
                                    class="bg-gray-50 border border-gray-500 text-black text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                                    options={stateDropdown.map(e => ({ label: e.area_name, value: e.area_id }))}
                                    singleSelect
                                    closeMenuOnSelect={true}// set list of the data
                                    onChange={handleSingleStateChange} // assign onChange function
                                    defaultValue={{ label: "All (India)", value: 1 }}
                                    maxMenuHeight="50"
                                    isDisabled={!isSelected}
                                />
                            }
                        </div>
                    </div>
                    <div className='flex w-full md:w-1/2 lg:w-1/6' id='distDropdown'>
                        <div class="flex-col w-11/12">
                            {level === "district" && <label for="" class="block mb-1 mt-2 md:mt-0 text-base font-bold text-black">Select District</label>}
                            {level === "district" &&
                                <Select
                                    class="bg-gray-50 border border-gray-500 text-black text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                                    options={districtDropdown.filter(district => state.includes(district.area_parent_id)).map(e => ({ label: e.area_name, value: e.area_id }))}
                                    closeMenuOnSelect={false}// set list of the data
                                    onChange={updatedAreas} // assign onChange function
                                    // isOptionDisabled={() => district.length >= 3}
                                    isMulti
                                    ref={selectInputRef}
                                    // maximumSelectionLength={3}
                                    maxMenuHeight="50"
                                    showCheckbox={true}
                                    hideSelectedOptions={false}
                                    onMenuClose={handleDistrictChange}
                                    isDisabled={!isSelected}
                                    components={{
                                        ValueContainer,
                                        Option
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}

export default AreaCompare