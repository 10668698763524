import React from 'react'
import './chart.css';
import _ from 'lodash';
import { createQualityData } from '../util';
import BootstrapTable from 'react-bootstrap-table-next';
import $ from 'jquery';
import 'bootstrap';
import { CSVLink } from 'react-csv';

export const DataQualityHolder = ({ qualityData, area }) => {
    let mainTitle, csvFileName;

    if (area === 1) {
        mainTitle = `${qualityData.graphTitle.indicator__label}, FY: ${qualityData.graphTitle.financial_year__fy_name}, Month: ${qualityData.graphTitle.month__month_name}`;
        csvFileName = `${qualityData.graphTitle.indicator__label}_${qualityData.graphTitle.financial_year__fy_name}_month_${qualityData.graphTitle.month__month_name}`;
    } else {
        mainTitle = `${qualityData.graphTitle.indicator__label}, FY: ${qualityData.graphTitle.financial_year__fy_name}, Month: ${qualityData.graphTitle.month__month_name}`;
        csvFileName = `${qualityData.graphTitle.indicator__label}_${qualityData.graphTitle.financial_year__fy_name}_month_${qualityData.graphTitle.month__month_name}`;
    }

    let filteredData = createQualityData(qualityData);
    let count_values = filteredData[0];
    let zipData = filteredData[1];
    let heading = ["Blocks Reported Zero", "Blocks Reported Null", "Blocks Reported Outlier", "Blocks Reported Duplicate"];

    let columns;

    const headerStyle = { width: '20%', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#A2D3FA', fontSize: '14px' };
    const cellStyle = { fontWeight: 'bold', textAlign: 'center' };

    columns = [{
        dataField: 'zero_count',
        text: 'Reported Zero',
        style: cellStyle,
        headerStyle,
    },
    {
        dataField: 'null_count',
        text: 'Reported Blank',
        style: cellStyle,
        headerStyle,
    },
    {
        dataField: 'outlier_count',
        text: 'Reported Outlier',
        style: cellStyle,
        headerStyle,
    },
    {
        dataField: 'duplicate_count',
        text: 'Reported Duplicate',
        style: cellStyle,
        headerStyle,
    }]

    const CaptionElement = () => <h5 style={{ fontWeight: 'bold', textAlign: 'center', color: 'black', border: '1px solid', margin: '0%', fontSize: '14px' }}>Data Quality at District Level</h5>;

    return (
        <div class="relative w-full h-full" className="data-quality-table">
            <div className="absolute right-2 left-2 md:right-5 md:left-5 mx-10 lg:mx-8 w-auto">
                <div className="text-center font-bold md:font-semibold lg:font-bold text-sm md:text-base lg:text-base pb-8">{`${mainTitle}`}</div>
            </div>
            <br />
            <BootstrapTable
                keyField='id' data={count_values} caption={<CaptionElement />} columns={columns} noDataIndication="Table is Empty" />
            <div class="flex justify-center">
                <CSVLink
                    data={zipData}
                    filename={csvFileName}
                    headers={heading}
                    target="_blank"
                >
                    <button class="bg-cyan-900 text-white font-bold text-lg py-2 px-4 mt-1 rounded-3xl hover:bg-gray-900" id="btnExport"><i class="bi bi-download"></i> List of Districts</button></CSVLink>
            </div>
        </div>
    )
}

export default DataQualityHolder